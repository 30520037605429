import React, { useState, useEffect } from "react";
import { Link, Navigate } from 'react-router-dom';
import configuration from '../config/configuration.json';
import LoteTimerOnly from "../components/LoteTimerOnly";
import NumberFormat from 'react-number-format';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Lottie from 'lottie-react';
import loader from '../img/loader.json';
import { Helmet } from "react-helmet";
import ShareButtons from "../components/ShareButtons";
import StarIcon from '@mui/icons-material/Star';
import VideosComponent from '../components/videos-component';
import FotosGaleria from '../components/fotosGaleria.js';
import ReactLightBox from '../components/reactLightbox';
import Cookies from 'universal-cookie';
import VideoLote from "../components/VideoLote";
import Preloader from '../components/Preloader';

const cookies = new Cookies();

const Puja = () => {
	const [lote, setLote] = useState([]);
	const [loading, setLoading] = useState(false);
	const remateId = window.location.href.split('/')[5];
	const loteId = window.location.href.split('/')[4];
	const [openDialogBuyout, setOpenDialogBuyout] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [newOffer, setNewOffer] = useState(0);
	const [currentOffer, setCurrentOffer] = useState(0);
	const [buyoutPrice, setBuyoutPrice] = useState(0);
	const [btnOfferBuyout, setBtnOfferBuyout] = useState(false);
	const [btnOffer, setBtnOffer] = useState(false);
	const [error404, setError404] = useState(false);
	const [offer, setOffer] = useState(0);
	const [alias, setAlias] = useState('');
	const [isYourOffer, setIsYourOffer] = useState(false);
	const [status, setStatus] = useState(false);
	const [toggle, setToggle] = useState(false);
	const [initialStatus, setInitialStatus] = useState(false);
	const [loteBackground, setLoteBackground] = useState('');
	const userMail = cookies.get('mail');
	const [imageShare, setImageShare] = useState('');

	const openDialogWindowBuyout = () => {
		setOpenDialogBuyout(true);
	}

	const closeDialogWindowBuyout = () => {
		setOpenDialogBuyout(false);
	}

	const decreaseOfferAmount = () => {
		if (newOffer > currentOffer) {
			setNewOffer(parseInt(newOffer) - parseInt(lote.lote.minOfferIncrement))
		}
	}

	const increaseOfferAmount = () => {
		setNewOffer(parseInt(newOffer) + parseInt(lote.lote.minOfferIncrement))
	}

	const openDialogWindow = () => {
		setOpenDialog(true);
	}

	const closeDialogWindow = () => {
		setOpenDialog(false);
	}

	const setSnackbarMsj = (msj, error) => {

		var snackbarMsj = document.getElementById('snackbar_msj');
		var snackbarBox = document.getElementById('snackbar_box');

		snackbarMsj.innerText = msj
		snackbarBox.classList.add("snackbar-hd");

		if (error) {
			snackbarBox.classList.add("snackbar-error");
		}

		setTimeout(() => {
			snackbarBox.classList.remove("snackbar-hd", "snackbar-error");
		}, 3000)
	}

	const performBuyout = (e) => {
		e.preventDefault();

		if (buyoutPrice > 0) {

			if (buyoutPrice < parseInt(newOffer) && buyoutPrice < parseInt(offer)) {
				setSnackbarMsj("El precio de compra directa no puede ser menor a la oferta actual", true)
				return null
			}
		}

		setBtnOfferBuyout(true);
		var date2 = new Date(lote.lote.end);

		if ((Math.floor(date2 / 1000) - Math.floor(Date.now() / 1000)) < 0) {
			setSnackbarMsj("El lote ha cerrado", true);
			return null
		}

		var a = parseInt(lote.lote.minOfferIncrement) + parseInt(offer)
		if (a > parseInt(newOffer)) {
			setBtnOfferBuyout(false);
			setSnackbarMsj("La oferta tiene que ser superior a: " + a, true);
			return null
		}

		if (cookies.get('token')) {

			fetch(process.env.REACT_APP_URL_API + configuration.INSTANT_BUYOUT, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"accept": "text/plain",
					'Authorization': 'Bearer ' + cookies.get('token')
				},
				body: JSON.stringify({
					offer: buyoutPrice,
					loteId: loteId,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					setBtnOfferBuyout(false);
					if (data.status === 'Ok') {
						setSnackbarMsj("Compra inmediata realizada con éxito", false);
					}
					else {
						setSnackbarMsj(data.data, true);
					}

					fetchLotes();
				})
				.catch((error) => console.log(error))

		}
		else {
			setSnackbarMsj("Debe iniciar sesión antes de comprar.", true);
			setBtnOfferBuyout(false);
		}
	}

	const onSubmit = (e) => {
		e.preventDefault();
		setBtnOffer(true);

		closeDialogWindow();

		var date2 = new Date(lote.lote.end);

		if ((Math.floor(date2 / 1000) - Math.floor(Date.now() / 1000)) < 0) {
			setSnackbarMsj("El tiempo para pre-ofertar ha terminado.", true);
			return null;
		}

		var a = parseInt(lote.lote.minOfferIncrement) + parseInt(offer)

		if (a > parseInt(newOffer)) {
			setBtnOffer(false);
			setSnackbarMsj("La oferta tiene que superior a: " + a, true);

			return null;
		}

		if (cookies.get('token')) {
			fetch(process.env.REACT_APP_URL_API + configuration.BID_UP, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					"accept": "text/plain",
					'Authorization': 'Bearer ' + cookies.get('token')
				},
				body: JSON.stringify({
					offer: newOffer,
					loteId: loteId
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					setBtnOffer(false);

					if (data.status === 'Ok') {
						setSnackbarMsj("Pre-oferta realizada con éxito", false);
					} else {
						setSnackbarMsj(data.data, true);
					}

					fetchLotes();
				})

		} else {

			setSnackbarMsj("Debe iniciar sesión antes de Pre-ofertar", true);
			setBtnOffer(false);
		}
	}

	const fetchLotes = () => {
		setLoading(true);
		fetch(process.env.REACT_APP_URL_API + configuration.GET_LOTE_FROM_REMATES, {
			method: 'POST',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"accept": "text/plain"
			},
			mode: 'cors',
			cache: 'default',
			body: JSON.stringify({ remateId: parseInt(remateId), userMail: userMail }),
		})
			.then((response) => response.json())
			.then((data) => {
				var loteFiltrado = data.data.loteList.filter(lote => { return lote.lote.id == loteId })
				if (loteFiltrado.length <= 0) {
					setError404(true);
				}
				else {

					//Initial lote states.
					let dateNow = new Date();
					let dateStart = new Date(loteFiltrado[0].lote.remateStartDate);
					let dateEnd = new Date(loteFiltrado[0].lote.end);

					if (dateNow < dateStart) {
						setLoteBackground('inactive-lote');
					}
					else if (dateNow > dateStart && dateNow < dateEnd) {
						setLoteBackground('active-lote');
					}
					else if (dateNow > dateEnd) {
						setLoteBackground('finished-lote');
					}

					setLote(loteFiltrado[0]);
				}
			})
			.catch((error) => console.log(error))
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		fetchLotes();
	}, []);

	useEffect(() => {
		if (lote.lote) {
			setOffer(lote.offer);
			setAlias(lote.userAlias);
			setBuyoutPrice(lote.lote.buyoutPrice);
			setIsYourOffer(lote.isYourOffer);
			setNewOffer(parseInt(lote.lote.minOfferIncrement) + parseInt(lote.offer));
			setCurrentOffer(parseInt(lote.lote.minOfferIncrement) + parseInt(lote.offer));

			//Image Share.
			if (lote.lote.imageUrl) {
				let images = lote.lote.imageUrl;

				if (images.length > 1) {
					let imagesArr = images.split(',');
					setImageShare(imagesArr[0]);
				}
				else {
					setImageShare(images);
				}
			}

			else if (lote.lote.videos) {
				let videos = lote.lote.videos;
				let a, b = ""

				let videosArr = videos.split(',');
				a = videosArr[0].split("v=")[1];

				if (a === undefined || a === "") {
					setImageShare(require('../img/placeholder.png'));
				}
				else {
					b = a.split('&')[0];
					setImageShare(`https://img.youtube.com/vi/${b}/hqdefault.jpg`);
				}
			}

			else {
				setImageShare(require('../img/placeholder.png'));
			}
		}
	}, [lote]);

	const focus = (e) => {
		e.target.select();
	}

	function updateStatus(status, initial) {
		setStatus(status);
		setInitialStatus(initial);
	}

	useEffect(() => {
		if (initialStatus) {
			setLoteBackground('inactive-lote');
		}
		else if (!initialStatus && status) {
			setLoteBackground('active-lote');
		}
		else if (!initialStatus && !status) {
			setLoteBackground('finished-lote');
		}
	}, [status, initialStatus]);

	if (error404) {
		return (<Navigate to="/error" />)
	}

	return (
		<>
			{!loading ?
				<div>
					{lote.lote && (
						<>
							<div id="snackbar_box">
								<div id="snackbar_msj"></div>
							</div>

							<Helmet>
								<meta charSet="utf-8" />
								<meta name="description" content={lote.lote.shortDescription} />
								<meta property="og:title" content={`Pre-ofertar Lote ${lote.lote.name} - Remate ${remateId} - El Rodeo Consignataria`} />
								<meta property="og:type" content="website" />
								<meta property="og:url" content={window.location.href} />
								<meta property="og:image" content={imageShare} />

								<meta name="twitter:title" content={`Pre-ofertar Lote ${lote.lote.name} - Remate ${remateId} - El Rodeo Consignataria`} />
								<meta name="twitter:description" content={lote.lote.shortDescription} />
								<meta name="twitter:image" content={imageShare} />
								<meta name="twitter:card" content="summary_large_image" />

								<title>Pre-ofertar Lote {lote.lote.name} Remate {remateId} - El Rodeo Consignataria</title>
								<link rel="canonical" href={window.location.href} />
							</Helmet>

							<div className="breadcrum">
								<u><Link to="/">Inicio</Link></u> {" > "}
								<u><Link to="/remates-feria">Remates Feria</Link></u> {" > "}
								<u><Link to={`/lote/${remateId}`}>Lotes</Link></u> {" > "}
								Pre-ofertar
							</div>

							<div className="form-m">
								<div className="pujaForm">

									<div className="pujaForm_container">
										<div className={`pujaForm_name ${loteBackground}`}>
											Lote {lote.lote.name} - Remate {remateId}
										</div>
										<div className="pujaForm_infobox">

											<div className="pujaForm_infobox_img">
												<VideoLote videos={lote.lote.videos} />
											</div>
											<div className="pujaForm_infobox_text">
												<h3>{lote.lote.owner}</h3>

												<LoteTimerOnly
													updateStatus={updateStatus}
													start={lote.lote.remateStartDate}
													end={lote.lote.end} />

												<div className="puja-detail-wrapper">
													<p><b>Cantidad:</b> {lote.lote.animales.length}</p>
													<p><b>Descripción:</b> {lote.lote.shortDescription}</p>
												</div>

												{isYourOffer ?
													<div className="lote-your-offer">
														<StarIcon />
														Tu Pre-oferta
													</div>
													:
													lote.lote.minOfferValue > 1 && (
														<div className="property-block">
															<p className="green-text">{alias ? `Ofertante: ${alias}` : "No hay ofertas"}</p>
														</div>
													)
												}

												<p className="p-share-text">Compartir en</p>
												<ShareButtons
													url={window.location.href}
													title={`Ofertar - Lote ${lote.lote.name} Remate ${remateId}`} />
											</div>
										</div>

										{status && (
											<div className="pujaForm_secondBox">
												<div>
													{lote.lote.minOfferValue > 1 &&
														<div className="pujaForm_input">
															<button className="buttonIncrease button-minus" onClick={decreaseOfferAmount}>-</button>

															<NumberFormat
																displayType='text'
																className="offerNumberValue"
																thousandSeparator={'.'}
																allowNegative={false}
																decimalSeparator={','}
																onFocus={focus}
																prefix={'Gs. '}
																value={newOffer}
																onValueChange={(a) => {
																	setNewOffer(a.value)
																}} />

															<button className="buttonIncrease button-plus" onClick={increaseOfferAmount}>+</button>
														</div>
													}
													<div className="pujaForm_botonera">
														{lote.lote.minOfferValue > 1 &&
															<button
																type="button"
																className={!btnOffer ? "pujaForm_botonera-ofertar" : "pujaForm_botonera-ofertar btn-disabled"}
																onClick={openDialogWindow}
																disabled={btnOffer}
															>
																{!btnOffer
																	?
																	"Pre-ofertar"
																	:
																	<Lottie
																		animationData={loader}
																		loop={true}
																		className="btn-lottie-loading-anim"
																	/>
																}

															</button>
														}

														{buyoutPrice > 0 &&
															<button
																type="button"
																className={!btnOffer ? "pujaForm_botonera-buyout" : "pujaForm_botonera-buyout btn-disabled"}
																onClick={openDialogWindowBuyout}
																disabled={btnOfferBuyout}
															>
																Compra Directa
															</button>
														}

														{false &&
															lote.lote.minOfferValue > 1 &&
															<button
																type="button"
																className="pujaForm_botonera-update"
															//onClick={fetchLotes}
															>
																Actualizar última pre-oferta
															</button>
														}
													</div>
												</div>

												<div className="pujaForm_data">
													{lote.lote.minOfferValue > 1 &&
														<>
															<div className="pujaForm_data_incremento">
																<p>Incremento minimo</p>
																<h5>{lote.lote.minOfferIncrement.toLocaleString('es-AR')} GS</h5>
															</div>

															<div className="pujaForm_data_oferta">
																<p>Última pre-oferta</p>
																<h5>{lote.offer.toLocaleString('es-AR')} Gs</h5>
															</div>
														</>
													}

													{buyoutPrice > 0 &&
														<div className="pujaForm_data_buyout">
															<p>Compra directa</p>
															<h5>{buyoutPrice.toLocaleString('es-AR')} Gs</h5>
														</div>
													}
												</div>
											</div>
										)}

										<div className="lote-information-container">
											<div className="ficha_datosPrincipales">
												{lote.lote.animales.map((animal, i) => (
													<div className="ficha_datosSecundarios" key={i}>
														{animal.propiedades.map((lote, i) => (

															<div className="ficha_datosSecundarios_cajas" key={i}>
																<p className="ficha_datosSecundarios_cajas_titulo">{lote.name}</p>
																<p className="ficha_datosSecundarios_cajas_dato">{lote.value}</p>
															</div>

														))}
													</div>
												))}
											</div>

											<div className="ficha_stats">

												{lote.lote.description &&
													<div className="ficha_stats_caja">
														<div className="ficha_stats_caja_img">
															<img src="./img/observaciones-icon.svg" alt="" />
														</div>
														<div className="ficha_stats_caja_titulo">
															Observaciones
														</div>
														<div className="ficha_stats_caja_texto">
															{lote.lote.description}
														</div>
													</div>
												}

											</div>
											<div id="video">
												{lote.lote.videos !== null &&
													<>
														<div className="ficha_stats_caja_titulo">
															Videos
														</div>
														<VideosComponent videos={lote.lote.videos} />
													</>
												}
											</div>

											<FotosGaleria img={lote.lote.imageUrl} toggle={setToggle} />
											<ReactLightBox toggle={toggle} img={lote.lote.imageUrl} />
										</div>

									</div>
								</div>
							</div>

							<Dialog
								open={openDialogBuyout}
								onClose={closeDialogWindowBuyout}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>

								<DialogTitle className="dialogTitle" id="alert-dialog-title">
									{"Confirmación de compra directa"}
								</DialogTitle>

								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										¿Estás seguro/a que deseas comprar el lote <b>{lote.lote.name}</b> por el monto <b>Gs. {buyoutPrice.toLocaleString('es-AR')}</b>?
									</DialogContentText>
								</DialogContent>

								<DialogActions className="dialogActionWrapper">
									<Button className="dialogBtn cancelDialogButton" onClick={closeDialogWindowBuyout}>Cancelar</Button>
									<Button className="dialogBtn submitDialogButton" onClick={performBuyout} autoFocus>
										Comprar
									</Button>
								</DialogActions>
							</Dialog>

							<Dialog
								open={openDialog}
								onClose={closeDialogWindow}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>

								<DialogTitle className="dialogTitle" id="alert-dialog-title">
									{"Confirmación de oferta"}
								</DialogTitle>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										¿Estás seguro/a que deseas ofertar por el lote <b>{lote.lote.name}</b> por el monto <b>Gs. {newOffer.toLocaleString('es-AR')}</b>?
									</DialogContentText>
								</DialogContent>

								<DialogActions className="dialogActionWrapper">
									<Button className="dialogBtn cancelDialogButton" onClick={closeDialogWindow}>Cancelar</Button>
									<Button className="dialogBtn submitDialogButton" onClick={onSubmit} autoFocus>
										Ofertar
									</Button>
								</DialogActions>
							</Dialog>

						</>
					)}
				</div>
				:
				<div className="lottie-loading">
					<Preloader />
				</div>
			}
		</>
	);
}

export default Puja;
