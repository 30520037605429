import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoteTimer from '../components/LoteTimer';
import Picture from '../components/picture';
import StarIcon from '@mui/icons-material/Star';
import Preloader from '../components/Preloader';

const Invernada = (props) => {

	const [status, setStatus] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		buyType: 0,
		btnOfferText: ''
	});

	function updateStatus(status) {
		setStatus(status);
		setLoading(false);
	}

	useEffect(() => {
		if (props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue <= 1) {
			setState((prev) => ({ ...prev, buyType: 1 }));
		}

		if (props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue >= 1) {
			setState((prev) => ({ ...prev, buyType: 0 }));
		}

		if (props.data.lote.buyoutPrice <= 0 && props.data.lote.minOfferValue >= 1) {
			setState((prev) => ({ ...prev, buyType: 2 }));
		}
	}, []);

	return (
		<>
			{!loading ?

				<div className="lote-wrapper">
					<LoteTimer
						updateStatus={updateStatus}
						name={props.data.lote.name}
						end={props.data.lote.end}
						start={props.data.lote.start} />

					<div className="global-accordion-container">
						<div className="lote-number-invernada-image">
							<Link to={"/lotes-disponibles/ofertar/" + props.data.lote.id} target="_blank">
								<Picture
									className="mobileSpacing"
									images={props.data.lote.images}
									videos={props.data.lote.videos} />
							</Link>
						</div>

						<div className="information-block-container">
							<div className="tablet-desktop-wrapper">
								<div className="timer-lote-offer-wrapper">
									<div className="lote-offer-type mobileSpacing">
										<div className={state.buyType == 1 ? "buy-type-block block-blue" : "buy-type-block block-green"}>
											{state.buyType == 1 ? "Compra directa" : "Pre-oferta"}
										</div>
									</div>

									{props.data.lote.minOfferValue > 1 && (
										<div className="lote-last-offer-wrapper">
											<p>ÚLTIMA PRE-OFERTA</p>
											<p>{props.data.offer.toLocaleString('es-AR')} Gs.</p>
										</div>
									)}
								</div>

								<div className="lote-properties">
									<div className="property-flex">
										<p>Establecimiento: </p>
										<p>{props.data.lote.stablishment}</p>
									</div>

									<div className="property-flex">
										<p>Localidad: </p>
										<p>{props.data.lote.location}</p>
									</div>

									{props.data.isYourOffer ?
										<div className="lote-your-offer">
											<StarIcon />
											Tu Pre-oferta
										</div>
										:
										props.data.lote.minOfferValue > 1 && (
											<div className="property-block">
												<p className="green-text">{props.data.userAlias ? `Ofertante: ${props.data.userAlias}` : "No hay ofertas"}</p>
											</div>
										)
									}
								</div>

								<div className="lote-action-buttons mobileSpacing">
									<Link to={"/lotes-invernada/ofertar/" + props.data.lote.id}
										props={{ minOfferIncrement: props.minOffer }}
										target="_blank"
										className={status ? "btn-lote btn-lote-offer" : "btn-lote btn-lote-disabled"}>
										{(props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue <= 1) && ("Comprar")}
										{(props.data.lote.buyoutPrice > 0 && props.data.lote.minOfferValue > 1) && ("Pre-ofertar")}
										{(props.data.lote.buyoutPrice <= 0 && props.data.lote.minOfferValue >= 1) && ("Pre-ofertar")}
									</Link>

									{!status && (
										<Link to={"/lotes-invernada/ofertar/" + props.data.lote.id}
											target="_blank"
											className="btn-lote btn-lote-show-more">
											Ver información del lote
										</Link>
									)}
								</div>
							</div>
						</div>

					</div>
					<div className="lotes-props-invernada">
						<div className="lote-props-wrapper">
							<div className="lote-prop-block">
								<div className="property-invernada-flex">
									<p>Categoría:</p>
									<p>{props.data.lote.category}</p>
								</div>

								<div className="property-invernada-flex">
									<p>Raza:</p>
									<p>{props.data.lote.race}</p>
								</div>

							</div>

							<div className="lote-prop-block">
								<div className="property-invernada-flex">
									<p>Pelaje:</p>
									<p>{props.data.lote.fur}</p>
								</div>
								<div className="property-invernada-flex">
									<p>Carimbo:</p>
									<p>{props.data.lote.carimbo}</p>
								</div>
							</div>

							<div className="lote-prop-block">

								<div className="property-invernada-flex">
									<p>Cantidad:</p>
									<p>{props.data.lote.quantity}</p>
								</div>

								<div className="property-invernada-flex">
									<p>Peso Promedio:</p>
									<p>{props.data.lote.averageWeight}</p>
								</div>
							</div>

							<div className="lote-prop-block">
								<div className="property-invernada-flex">
									<p>Observación Corta:</p>
									<p>{props.data.lote.shortObs}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				<div className="lottie-loading">
					<Preloader />
				</div>
			}
		</>
	);
}

export default Invernada;
