import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import { Link, Navigate } from 'react-router-dom';
import FotosGaleria from '../components/fotosGaleria';
import VideosComponent from '../components/videos-component';
import ReactLightBox from '../components/reactLightbox';
import { HashLink } from 'react-router-hash-link';
import Lottie from 'lottie-react';
import ovni from '../json/ovni.json';
import loader from '../img/loader.json';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from "react-helmet";
import ShareButtons from "../components/ShareButtons";
import StarIcon from '@mui/icons-material/Star';
import cookie from 'js-cookie';

import {
	Bar,
	Label,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ComposedChart,
	ResponsiveContainer
} from 'recharts';

import LoteTimer from '../components/LoteTimer';
import VideoLote from '../components/VideoLote';

const invernadaFicha = () => {

	const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const isFromTablet = useMediaQuery({ minWidth: '46.25em' });
	const loteId = window.location.href.split('/')[4];

	const [status, setStatus] = useState(false);
	const [loteFiltrado, setLoteFiltrado] = useState(null);
	const [weightDistributions, setWeightDistributions] = useState([]);
	const [chartData, setChartData] = useState(null);
	const [statusBar, setStatusBar] = useState(null);
	const [error, setError] = useState(false);
	const [toggle, setToggle] = useState(false);

	const [state, setState] = useState({
		width: window.innerWidth / 1.7,
		height: 150,
		error404: false,
		intervalId: [],
		timerClass: 'ficha_data_timer',
		proximamente: false,
		chartData: [],
		loteFiltrado: null,
		loading: false,
		error: null,
		data: [],
		weightDistributions: []
	});

	useEffect(() => {
		setState({ loading: true });
		let ignore = false;

		async function fetchData() {
			await axios.post(process.env.REACT_APP_URL_API + configuration.GET_INVERNADA_LOTES, {
				userMail: cookie.get('mail')
			}).then((response) => {
				if (!ignore) {
					console.log(response);
					let a = response.data.data.loteList;

					let b = a.filter(lote => {
						return lote.lote.id == loteId;
					})

					if (b.length == 0) {
						console.log("Es 0");
						setError(true);
					}

					if (b.length > 0) {
						setLoteFiltrado(b[0]);
					}

					let chartArray = [];

					if (b[0].lote.weightDistributions.length > 0) {
						b[0].lote.weightDistributions.map(dist => {
							let dataName = `${dist.minRange} - ${dist.maxRange}`;
							chartArray.push({ name: dataName, qty: dist.quantity });
						});
					}

					chartArray.sort((a, b) => {
						return a.name.localeCompare(b.name);
					})

					setChartData(chartArray);
				}
			})
				.catch((error) => {
					console.log(error);
				})
				.then(() => {
					setState({ loading: false });
				})
		}

		fetchData();

		return () => {
			ignore = true;
		}
		//getStatus();
		//window.addEventListener('resize', handleResize())
	}, []);

	useEffect(() => {
		let statusInterval = setInterval(getStatus, 1000);

		return () => {
			clearInterval(statusInterval);
		}
	}, [loteFiltrado]);

	const getStatus = () => {
		if (loteFiltrado) {
			//setState({ loading: true });
			var dateStart = new Date(loteFiltrado.lote.start);
			var dateEnd = new Date(loteFiltrado.lote.end);
			var dateNow = new Date();

			if (Math.floor(dateEnd / 1000) - Math.floor(dateNow / 1000) > 0) {
				if (Math.floor(dateStart / 1000) - Math.floor(dateNow / 1000) > 0) {
					setStatus(false);
					setStatusBar(
						<div className="ficha_info ficha_info-proximamente">
							{loteFiltrado ? loteFiltrado.lote.name : ""}
						</div>
					);
				} else {
					setStatus(true);
					setStatusBar(
						<div className="ficha_info ficha_info-open">
							{loteFiltrado ? loteFiltrado.lote.name : ""}
						</div>
					);
				}
			} else {
				setStatus(false);
				setStatusBar(
					<div className="ficha_info ficha_info-close">
						{loteFiltrado ? loteFiltrado.lote.name : ""}
					</div>
				);
			}
		}
	}

	if (error) {
		console.log("Si es error404");
		return (
			<div className="lottie-loading">

				<Lottie
					animationData={ovni}
					loop={true}
					className="lottie-loading-anim"
				/>
				<h4 className="textoConsola">Ops! No se ha encontrado ningun lote.</h4>
			</div>
		)
		//return (<Navigate to="/error"/>)
	}
	else {
		return (
			<>
				{!state.loading ?
					<div>

						<div className="breadcrum">
							<Link to="/">Inicio</Link> / <HashLink to={"/lotes-disponibles" + "#" + loteId}>Lotes Disponibles</HashLink> / Ficha Técnica

						</div>
						{loteFiltrado && (
							<div className="ficha_wrapp">
								<Helmet>
									<meta charSet="utf-8" />
									<title>Lote Disponible ficha técnica - {loteFiltrado.lote.name} - El Rodeo Consignataria</title>
									<meta
										property="og:title"
										content={`Lote Disponible ficha técnica - ${loteFiltrado.lote.name}`} />
									<link rel="canonical" href={window.location.href} />
									<meta
										property="og:url"
										content={window.location.href} />
								</Helmet>

								<div className="ficha">
									{statusBar}

									<div className="content-spacing">
										<div className="ficha_data">
											<div className="ficha_data_img">
												<VideoLote videos={loteFiltrado ? loteFiltrado.lote.videos : ""} />

												<ShareButtons
													url={window.location.href}
													title={`Lote Disponible Ficha Técnica - ${loteFiltrado.lote.name}`} />
											</div>

											<div className="ficha_data_text">
												<div className="ficha_data_text_nombre">
													<p>Lote Disponible</p>
													<h5> {loteFiltrado ? loteFiltrado.lote.owner : ""} </h5>
												</div>

												<div className="ficha_data_text_puja">
													{loteFiltrado.isYourOffer
														?
														<div className="lote-your-offer">
															<StarIcon />
															TU OFERTA
														</div>
														:
														<div className="ficha_data_text_puja_badget">
															<div className="pujaForm_data_anotherOffer">
																<p>
																	<span style={{ fontWeight: 700, marginRight: '3px' }}>{
																		loteFiltrado.userAlias ? "Ofertante:" : "No hay ofertante"}</span>
																	{loteFiltrado.userAlias ? loteFiltrado.userAlias : ""}
																</p>
															</div>
														</div>
													}

													<LoteTimer
														showMore={true}
														end={loteFiltrado.lote.end}
														start={loteFiltrado.lote.start}
													/>

													<div className="no-border lote-last-offer-wrapper">
														<p className="no-padding">ÚLTIMA OFERTA</p>
														<p className="no-padding">{loteFiltrado.offer.toLocaleString('es-AR')} Gs.</p>
													</div>
												</div>
											</div>

										</div>

										<div className="ficha_botonera">
											<div className="navbar_botonera">

												<HashLink to={"/lotes-disponibles" + "#" + loteFiltrado.lote.id}>
													<div className="btn btn-ppl">
														VOLVER
													</div>
												</HashLink>

												{!status
													?
													<div className="btn btn-disabled">
														OFERTAR
													</div>
													:
													<Link to={"/lotes-disponibles/ofertar/" + loteFiltrado.lote.id}>
														<div className="btn btn_cta">
															OFERTAR
														</div>
													</Link>
												}

											</div>
										</div>

										<div className="ficha_datosPrincipales">
											<div className="ficha_datosSecundarios">


												<div className="ficha_datosSecundarios_cajas">
													<p className="ficha_datosSecundarios_cajas_titulo">
														Categoría
													</p>
													<p className="ficha_datosSecundarios_cajas_dato">
														{loteFiltrado ? loteFiltrado.lote.category : ""}
													</p>
												</div>

												<div className="ficha_datosSecundarios_cajas">
													<p className="ficha_datosSecundarios_cajas_titulo">
														Raza
													</p>
													<p className="ficha_datosSecundarios_cajas_dato">
														{loteFiltrado ? loteFiltrado.lote.race : ''}
													</p>
												</div>

												<div className="ficha_datosSecundarios_cajas">
													<p className="ficha_datosSecundarios_cajas_titulo">
														Pelaje
													</p>
													<p className="ficha_datosSecundarios_cajas_dato">
														{loteFiltrado ? loteFiltrado.lote.fur : ''}
													</p>
												</div>

												<div className="ficha_datosSecundarios_cajas">
													<p className="ficha_datosSecundarios_cajas_titulo">
														Carimbo
													</p>
													<p className="ficha_datosSecundarios_cajas_dato">
														{loteFiltrado ? loteFiltrado.lote.category : ''}
													</p>
												</div>

												<div className="ficha_datosSecundarios_cajas">
													<p className="ficha_datosSecundarios_cajas_titulo">
														Cantidad
													</p>
													<p className="ficha_datosSecundarios_cajas_dato">
														{loteFiltrado ? loteFiltrado.lote.quantity : ''}
													</p>
												</div>

												<div className="ficha_datosSecundarios_cajas">
													<p className="ficha_datosSecundarios_cajas_titulo">
														Peso Promedio
													</p>
													<p className="ficha_datosSecundarios_cajas_dato">
														{loteFiltrado ? loteFiltrado.lote.averageWeight : ''}
													</p>
												</div>


											</div>
										</div>

										<div className="ficha_stats">
											<div className="ficha_stats_caja">
												<div className="ficha_stats_caja_img">
													<img src="./img/observaciones-icon.svg" alt="" />
												</div>
												<div className="ficha_stats_caja_titulo">
													Observaciones
												</div>
												<div className="ficha_stats_caja_texto">
													{loteFiltrado ? loteFiltrado.lote.longObs : ''}
												</div>
											</div>
										</div>

										<div id="video">
											{loteFiltrado.lote && (
												<VideosComponent videos={loteFiltrado.lote.videos} />
											)}
										</div>

										{chartData && (
											chartData.length > 0 && (
												<ResponsiveContainer
													className="chart-container"
													width={isMobileToTablet ? "100%" : 500}
													height={350}>

													<ComposedChart
														width={500}
														height={350}
														data={chartData}
														margin={{
															top: 5,
															right: 30,
															bottom: 30,
															left: 20
														}}
													>

														<CartesianGrid stroke="#f5f5f5" />
														<XAxis dataKey="name">
															<Label value="Rangos" offset={-10} position="insideBottom" fontSize="14" fill="#19513c" />
														</XAxis>
														<YAxis label={{ value: 'Cantidad', angle: -90, position: 'insideLeft', fontSize: 14, fill: '#19513c' }} />
														<Tooltip />

														<Bar dataKey="qty" name="Cantidad" fillOpacity={1} fill="#19513c" />
													</ComposedChart>
												</ResponsiveContainer>
											)
										)}

										<FotosGaleria img={loteFiltrado.lote.images} toggle={setToggle} />
										<ReactLightBox toggle={toggle} img={loteFiltrado.lote.images} />
									</div>
								</div>
							</div>
						)}
					</div>
					:
					<div className="lottie-loading">
						<Lottie
							animationData={loader}
							loop={true}
							className="lottie-loading-anim"
						/>
					</div>
				}
			</>
		)
	}
}

export default invernadaFicha;
