import React, { useState, useEffect } from 'react';
import configuration from '../config/configuration.json';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Lottie from 'lottie-react';
import ovni from '../json/ovni.json';
import BannerImage from '../components/BannerImage';
import { useMediaQuery } from 'react-responsive';
import LoteRepMobile from '../components/LoteRepMobile';
import LoteRep from '../components/LoteRep';
import Preloader from '../components/Preloader';

const Lotes = () => {
	let rid = window.location.href.split('/')[4];

	if (rid.indexOf("#") + 1) {
		rid = rid.substring(0, rid.indexOf("#"));
	}

	const [notFound, setNotFound] = useState(false);
	const cookies = new Cookies();
	const isMobileToTablet = useMediaQuery({ minWidth: '20em', maxWidth: '46.24em' });
	const isFromTablet = useMediaQuery({ minWidth: '46.25em' });
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [bannerImages, setBannerImages] = useState([]);
	const [catalog, setCatalog] = useState(null);

	useEffect(() => {
		setLoading(true);
		fetch(process.env.REACT_APP_URL_API + configuration.GET_LOTE_FROM_REMATES, {
			method: 'POST',
			credentials: 'include',
			headers: {
				"Content-Type": "application/json",
				"accept": "text/plain"
			},
			mode: 'cors',
			cache: 'default',
			body: JSON.stringify({ remateId: rid, userMail: cookies.get('mail') })
		})
			.then((response) => response.json())
			.then(
				(data) => {
					if (data.status === "Error") {
						setNotFound(true);
					}
					// Ordenar los lotes por la propiedad "order"
					data.data.loteList.sort((a, b) => a.lote.order > b.lote.order ? 1 : -1).map(lote => {
						return lote;
					});

					setData(data.data);
				})
			.catch((error) => {
				console.log("Esto es un error: " + error)
			})
			.finally(() => {
				setLoading(false);
			});

	}, []);

	useEffect(() => {
		let imagesArr = [];

		if (data && data.files) {
			data.files.map((file, key) => {
				if (file.fileType === 'BannerImage') {
					if (file.file) {
						let imageUrl = `${process.env.REACT_APP_URL_BASE}/Uploads/${file.file.storedFileName}`;
						imagesArr.push(imageUrl);
					}
				}

				if (file.fileType === 'Catalog') {
					if (file.file) {
						let catalogUrl = `${process.env.REACT_APP_URL_BASE}/Uploads/${file.file.storedFileName}`;
						setCatalog(catalogUrl);
					}
				}
			})
			setBannerImages(imagesArr);
		}
	}, [data]);

	if (notFound) {
		return (
			<div className="lottie-loading">
				<Lottie
					animationData={ovni}
					loop={true}
					className="lottie-loading-anim"
				/>
				<h4 className="textoConsola">No se ha encontrado ningún lote disponible.</h4>
			</div>
		)
	}
	else {

		return (
			<>
				{!loading ?
					<>
						{bannerImages.length > 0 && (
							<div className="lotesBig_portada">
								<BannerImage images={bannerImages} />
							</div>
						)}

						<div className="breadcrum">
							<u><Link to="/">Inicio</Link></u> {" > "}
							<Link to="/remates-feria">Remates Feria</Link> {" > "}
							Lotes
						</div>

						{catalog && (
							<div className="text-center mt-3">
								<a className="btn-ppl btn-pdf" href={catalog} target="_blank">
									DESCARGAR CATÁLOGO
								</a>
							</div>
						)}

						{isMobileToTablet && (
							<div className="lotes-container">
								{data.loteList && (
									data.loteList.map((lote, i) => (
										<LoteRepMobile
											key={i}
											path={lote.lote.remateId}
											start={lote.lote.remateStartDate}
											end={lote.lote.end}
											mi={lote.lote.id}
											buyoutPrice={lote.lote.buyoutPrice}
											name={lote.lote.name}
											remated={lote.lote.remateId}
											orden={lote.lote.order}
											propietario={lote.lote.owner}
											base={lote.offer}
											minOffer={lote.lote.minOfferIncrement}
											minOfferValue={lote.lote.minOfferValue}
											animales={lote.lote.animales}
											imagen={lote.lote.imageUrl}
											owner={lote.lote.owner}
											short={lote.lote.shortDescription}
											video={lote.lote.videos}
											yours={lote.isYourOffer}
											alias={lote.userAlias}
											shortDescription={lote.lote.shortDescription}
										/>
									))
								)}
							</div>
						)}

						{isFromTablet && (
							<div className="lotes-container">
								{data.loteList && (
									data.loteList.map((lote, i) => (
										<LoteRep
											key={i}
											path={lote.lote.remateId}
											start={lote.lote.remateStartDate}
											end={lote.lote.end}
											mi={lote.lote.id}
											buyoutPrice={lote.lote.buyoutPrice}
											name={lote.lote.name}
											remated={lote.lote.remateId}
											orden={lote.lote.order}
											propietario={lote.lote.owner}
											base={lote.offer}
											minOffer={lote.lote.minOfferIncrement}
											minOfferValue={lote.lote.minOfferValue}
											animales={lote.lote.animales}
											imagen={lote.lote.imageUrl}
											owner={lote.lote.owner}
											short={lote.lote.shortDescription}
											video={lote.lote.videos}
											yours={lote.isYourOffer}
											alias={lote.userAlias}
											shortDescription={lote.lote.shortDescription}
										/>
									))
								)}
							</div>
						)}
					</>
					:
					<div className="lottie-loading">
						<Preloader />
					</div>
				}
			</>
		)
	}
}

export default Lotes;
